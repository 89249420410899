
import { QueryFilter } from "@directus/sdk";
import { DateTime } from "luxon";
import { Collections, Schema } from "../../models/DirectusSchema";

export const getDirectusDateFilter = (
  { dateField, dateString }:
    { dateString: string, dateField: string }
) => {
  // dateString could be any part of date string formatted like below:
  // yyyy-MM-dd HH:mm
  // e.g: yyyy, yyyy-MM, yyyy-MM-dd, MM-dd, yyyy-MM-dd HH:mm, HH:mm, dd HH, dd HH:mm ...
  const filter: Array<QueryFilter<Schema, Collections.ContactsOnObjects>> = [];

  if (/^\d{2}-\d{2}$/.test(dateString)) {
    // MM-dd format
    const [month, day] = dateString.split('-').map(Number);
    filter.push(
      { [`month(${dateField})`]: { _eq: month } }
    );
    filter.push(
      { [`day(${dateField})`]: { _eq: day } }
    );
    return filter;
  }

  if (/^\d{2}:\d{2}$/.test(dateString)) {
    // HH:mm format
    const [hour, minute] = dateString.split(':').map(Number);
    filter.push({
      [`hour(${dateField})`]: { _eq: hour > 3 ? hour - 3 : hour } // Quite agly solution to avoid timezone issues
    });
    filter.push({
      [`minute(${dateField})`]: { _eq: minute }
    });
    return filter;
  }

  const formats = [
    "yyyy",
    "yyyy-MM",
    "yyyy-MM-dd",
    "yyyy-MM-dd HH:mm",
    "dd HH",
    "dd HH:mm"
  ];

  for (const format of formats) {
    const parsed = DateTime.fromFormat(dateString.replace(/\s+/, " ").trim(), format);
    if (parsed.isValid) {
      const components = parsed.toObject();

      if (format === "yyyy") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
      } else if (format === "yyyy-MM") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
      } else if (format === "yyyy-MM-dd") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
      } else if (format === "yyyy-MM-dd HH:mm") {
        filter.push({ [`year(${dateField})`]: { _eq: components.year } });
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      } else if (format === "MM-dd HH:mm") {
        filter.push({ [`month(${dateField})`]: { _eq: components.month } });
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      } else if (format === "dd HH") {
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
      } else if (format === "dd HH:mm") {
        filter.push({ [`day(${dateField})`]: { _eq: components.day } });
        filter.push({ [`hour(${dateField})`]: { _eq: components.hour > 3 ? components.hour - 3 : components.hour } });
        filter.push({ [`minute(${dateField})`]: { _eq: components.minute } });
      }

      return filter;
    }
  }

  return filter;
}
