import React, { useContext, useState, useEffect } from 'react';
import { TextInput, SimpleInTableCheckbox } from "../inputElements";
import { MainContext } from '../MainContext';
import { getRidOfAliases } from '../../services/littleHelpers';
import formatPhoneNumber from './phoneNumbersFormatter';

const makeEditIcon = <svg
    xmlns="http://www.w3.org/2000/svg"
    className="icon-edit"
    width={44}
    height={44}
    viewBox="0 0 24 24"
    strokeWidth="1.5"
    stroke="currentColor"
    fill="none"
    strokeLinecap="round"
    strokeLinejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
        <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
        <line x1={16} y1={5} x2={19} y2={8} />
</svg>

const PhoneTableCell = ({cell, allowedRoles}) => {
    const { userData } = useContext(MainContext);
    const [canEdit, setCanEdit] = useState(false);
    const [onEditMode, setOnEditMode] = useState(false);
    let phoneLinks = cell.value || "";
    const [result, ] = useState(() => { return formatPhoneNumber(phoneLinks) } );
    const [editModeSwitchVisible, setEditModeSwitchVisible] = useState(false);

    useEffect(() => {

        if (allowedRoles?.length > 0) {
    
            allowedRoles.forEach(role => {
    
                if (userData.roles.includes(role)) {
                    setCanEdit(true);
                }
    
            })
    
        } else {
            setCanEdit(false);
        }
    
    }, []);

    const canEditContactPhones = (userRoles) => {

        if (userRoles.includes('dev')) {
            return false;
        } else {
            return true;
        }

    }

    // const goToEditMode = () => {
    //     setOnEditMode(true)
    // }


    return (
        <div
            style={{position:"relative"}}
            onMouseEnter={() => { setEditModeSwitchVisible(true) }} 
            onMouseLeave={() => { setEditModeSwitchVisible(false) }}
        >
            { onEditMode ?
                <TextInput
                    cell={cell}
                    textArea={true}
                    targetEndpoint="contacts_on_objects"
                    customDisablingFunction={canEditContactPhones}
                />
                :
                <>
                    <p>{result}</p>
                    {
                        editModeSwitchVisible && canEdit && 
                        <button 
                            className='enterEditModeButton'
                            tabIndex={-1}
                            onClick={() => setOnEditMode(true)}
                        >
                            {makeEditIcon}
                        </button>
                    }
                </>
            }
        </div>
    );
}


const ContactRow = ({ preparedRow, deletingRowsRef, justAlteredRowsRef }) => {
    const { userData, siteSettings } = useContext(MainContext);

    const cellRenderFunction = (cell) => {

        let backhoeIcon = 
            <svg xmlns="http://www.w3.org/2000/svg" className="icon-backhoe" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"/>
                <circle cx="4" cy="17" r="2" />
                <circle cx="13" cy="17" r="2" />
                <line x1="13" y1="19" x2="4" y2="19" />
                <line x1="4" y1="15" x2="13" y2="15" />
                <path d="M8 12v-5h2a3 3 0 0 1 3 3v5" />
                <path d="M5 15v-2a1 1 0 0 1 1 -1h7" />
                <path d="M21.12 9.88l-3.12 -4.88l-5 5" />
                <path d="M21.12 9.88a3 3 0 0 1 -2.12 5.12a3 3 0 0 1 -2.12 -.88l4.24 -4.24z" />
            </svg>

        if (cell.column.id === "object") {
            let destElements = [];

            cell.value?.forEach(d => {

                destElements.push(
                    <span 
                        className={(d?.status?.includes("Строящийся") || d?.status?.includes("На реконструкции")) ? "on-construction" : ""} 
                        title={siteSettings.tooltips && d.area_name + `${d?.status ? " — " + d?.status.replace(",", ", ") : ""}`}
                        key={`object-in-${cell.row.id}-row-${d.id}`}
                    >
                        {(d?.status?.includes("Строящийся") || d?.status?.includes("На реконструкции")) && backhoeIcon}
                        <p>{getRidOfAliases(d.area_name)}</p>
                    </span>
                )

            });

            return (
                destElements
            );

        } else if (cell.column.id === "email") {

            const canEdit = (userRoles) => {

                if (userRoles.includes('dev')) {
                    return false;
                } else {
                    return true;
                }

            }

            return(
                <TextInput
                    cell={cell}
                    textArea={true}
                    targetEndpoint="contacts_on_objects"
                    customDisablingFunction={canEdit}
                />
            );

        } else if (cell.column.id === "phone") {

            return <PhoneTableCell cell={cell} allowedRoles={["dev"]} />

        } else if (cell.column.id === "comment") {

            const canEditComment = (userRoles) => {

                if (userRoles.includes('dev')) {
                    return false;
                } else {
                    return true;
                }

            }

            return(
                <TextInput
                    cell={cell}
                    textArea={true}
                    targetEndpoint="contacts_on_objects"
                    customDisablingFunction={canEditComment}
                />
            );
            
        } else if (cell.column.id === "archived") {
            let disabled = true;

            // if (userData.roles.includes("admin")) {
            //     disabled = false;
            // }

            return (
                <SimpleInTableCheckbox
                    cell={cell}
                    justAlteredRowsRef={justAlteredRowsRef}
                    targetEndpoint={'contacts'}
                    isChecked={cell.value}
                    disabled={disabled}
                />
            );
        }

        return cell.render('Cell');
    }

    const { key, ...rowProps } = preparedRow?.getRowProps() || {};

    return (
        <>
            <tr 
                key={`row-updated-at-${preparedRow?.original?.updated_at_full}-${key}`}
                id={`contact-entry-id-${preparedRow.original.origin_ID}`}
                className={`contact-entry`}
                {...rowProps}
            >
                {
                    preparedRow.cells.map((cell, i) => {
                      
                      const { key, ...cellProps } = cell?.getCellProps() || {};
                      return (
                        <td 
                              className={`cell-in-${cell.column.id} boxProperty`} 
                              key={`${cell.row.original.origin_ID}-${cell.column.header}-${key}`} 
                              {...cellProps}
                          >
                              { cellRenderFunction(cell) }
                          </td>
                      );
                    })
                }
            </tr>
        </>
    );
}


function rowsPropsAreEqual(prevRow, nextRow) {

    if (prevRow.preparedRow.original.origin_ID === nextRow?.preparedRow?.original?.origin_ID) {
        
        if (prevRow.preparedRow.original.updated_at_full === nextRow?.preparedRow?.original?.updated_at_full) {
            return true;
        }

    }

    return false;

  }

const ContactRowMemoised = React.memo(ContactRow, rowsPropsAreEqual)

export default ContactRowMemoised;