import { readItems } from "@directus/sdk";
import { getDirectusClient } from "../DirectusSDKClient";
import { getDirectusDateFilter } from "./directusHelpers";
import { chooseEndpoint } from "../CRUDJournalData";

const logAbortError = (err, actionName = "") => {

  if (err.name === 'AbortError') {
    console.log(`%c${actionName} aborted`, "color:gray");
  } else {
    console.log(`%c${actionName} failed bacause of the following error:`, "color:gray");
    console.error(err)
  }
}


export const confirmDirectusDataWasUpdated = async ({
  source,
  sortBy = "-updated_at",
  fieldToCompareBy = "updated_at",
  lastKnownUpdateTimeString,
  knownTotalCount,
  filters,
  currentViewFilterCondition,
  recordsLimit,
  pageIndex,
  controller,
  timeout = 160000,
}) => {
  const filter = filters.reduce((acc, current) => {
    if (current.id === "object") {
      acc["_and"].push({
        "lands_n_objects_id": {
          "area_name": {
            "_icontains": current.value
          }
        }
      });
    } else if (["updated_at", "created_at"].includes(current.id)) {
      const dateConditions = getDirectusDateFilter({
        dateField: current.id,
        dateString: current.value,
      })
      acc["_and"] = acc["_and"].concat(dateConditions);
    } else {
      acc["_and"].push({
        [current.id]: {
          "_icontains": current.value
        }
      });
    }
    return acc;
  }, { "_and": [] });

  if (currentViewFilterCondition?.includes("archived,eq,false")) {
    filter["_and"].push({
      archived: {
        _eq: false
      }
    });
  }

  const targetEndpoint = chooseEndpoint(source);
  const client = getDirectusClient();
  // @ts-expect-error js junction imported will be typed one day, not this very day
  const response = await client.request(readItems(targetEndpoint, {
    limit: 3,
    offset: (recordsLimit && pageIndex) && (recordsLimit * pageIndex !== 0) ? recordsLimit * pageIndex : 0,
    //// @ts-expect-error let it be errored, in case if incorrect field name was provided on funcion call
    sort: sortBy,
    filter,
    fields: [
      "id",
      //// @ts-expect-error let it be errored, in case if incorrect field name was provided on funcion call
      `${fieldToCompareBy}`
    ],
    signal: controller.signal,
  })).catch((err) => {
    logAbortError(err, `confirmDataWasUpdated call on ${source}`)
  });

  if (response) {
    try {
      const localForComparison = lastKnownUpdateTimeString;
      const remoteForComparison = response?.[0]?.[`${fieldToCompareBy}`];

      if (
        localForComparison === remoteForComparison
      ) {
        return {
          // @ts-expect-error let error be here
          id: response[0].id,
          fresh: false
        }
      }

    } catch (err) {
      console.error(err)
      console.log("unrecognized error above got: response", response);
      return { fresh: false };
    }

  } else {
    // return { fresh: false };
    return undefined;
  }

}
